import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import Highlight from 'components/content-components/Highlight';

const HighlightDataTransformerBlock = ({ content }: any) => {
    const { anchorTag, links, tag } = content;

    if (!links.length) {
        return null;
    }

    const transformedLinks = links.map(linkitem => {
        const { backgroundMobile, backgroundDesktop } = linkitem;

        const transformedBackgrounds = transformFlexibleBackgrounds(
            [backgroundMobile[0], null, null, null, backgroundDesktop[0]],
            0.5
        );

        const backgroundOverlays = extractBackgroundOverlays([
            backgroundMobile[0],
            null,
            null,
            null,
            backgroundDesktop[0],
        ]);

        return {
            background: transformedBackgrounds,
            backgroundOverlay: backgroundOverlays,
            component: linkitem.component,
            follow: linkitem.follow,
            label: linkitem.label,
            link: linkitem.link,
            uid: linkitem._uid,
        };
    });

    return (
        <Highlight
            content={content}
            items={transformedLinks}
            id={anchorTag?.[0]?.anchorTag}
            tag={tag?.[0]?.tag}
            imageSettings={{ width: [200, 300, 400, 500, 600], sizes: ['48vw', null, null, '33vw'] }}
            slidesPerView={[1.3, null, 3.2, null, 5]}
        />
    );
};

export default HighlightDataTransformerBlock;
